<template>
  <section class="products container">
    <div class="products__banner" @click="goToCatalog">
      <img :src="banner" alt="">
    </div>

    <section class="evanorm">
      <div class="container">
        <div class="evanorm__part">
          <div class="products__img">
            <img src="@/assets/img/evabox.png" alt="" />
          </div>
          <div class="evanorm__text">
            <h2>Evanorm</h2>
            <p>
              Комплексный бокс Evanorm способствует лечению женского бесплодия и
              ранней менопаузы, устраняет воспалительные процессы, обладают
              антибактериальным и противогрибковым свойствами. Восстанавливают
              интимную микрофлору организма. Препарат на основе натуральных
              трав, против эрозии шейки матки. Профилактика образования и
              лечение эрозии. Ликвидация воспалительных и инфекционных
              процессов. Предотвращение повторных заболеваний.
            </p>
            <div class="evanorm__about">
              <button @click="productDes(75)">Подробнее</button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="marsilan">
      <div class="container">
        <div class="marsilan__part">
          <div class="marsilan__text">
            <h2>Marsilan</h2>
            <p>
              Комплексно воздействует на предстательную железу – нормализует ее
              состояние, уничтожает боль и улучшает потенцию. Препарат обладает
              обезболивающим, антибактериальным и противовоспалительным
              свойствами. Он подавляет очаги воспаления и быстро дает результат,
              при этом снижая боль и жар. Кроме того, нормализуется
              мочеиспускание, устраняются боль, недержание и частые позывы.
              Биогенная формула комплекса рекомендована в любом возрасте при
              снижении половой активности или полной дисфункции полового органа,
              т.к. оказывает индивидуальное регулирующее действие на причины
              возникновения эректильной дисфункции психогенного, эндокринного,
              урологического, нервного, иммунного характера.
            </p>
            <div class="marsilan__about">
              <button @click="productDes(69)">Подробнее</button>
            </div>
          </div>
          <div class="products__img">
            <img
              class="marsilan__img"
              src="https://back.rizaherb.kz/image/cache/catalog/riza/mars_sas-500x500.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <section class="sulu">
      <div class="container">
        <div class="sulu__part">
          <div class="products__img">
            <img
              class="sulu__img1"
              src="https://back.rizaherb.kz/image/cache/catalog/riza/sulu_patch-500x500.png"
              alt=""
            />
            <!-- <img class="sulu__img2" src="@/assets/img/sulu_front.png" alt="" /> -->
          </div>
          <div class="sulu__text">
            <h2>Sulu</h2>
            <p>
              Уникальная маска из семян морских водорослей, оказывает
              эффективное глубокое воздействие на структуру клеток.
              Восстанавливает структуры коллагена в коже, делая кожу упругой,
              эластичной, молодой после первого применения. На 100% состоит из
              натурального сырья – это семена морских водорослей, мелкие семена
              темно-коричневого цвета и маленького размера, которые являются
              источником природного коллагена и способны быстро возродить и
              оживить кожу лица, подходит для любого типа кожи. Регулирует
              работу сальных желез. Способствует выработке естественного
              коллагена. Увлажняет кожу. Обладает антисептическими и
              противогрибковыми свойствами. Решает проблему воспалений и
              высыпаний на коже. Разглаживает морщины. Улучшает цвет лица.
            </p>
            <div class="sulu__about">
              <button @click="productDes(64)">Подробнее</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  data() {
    return {
      banner: ''
    }
  },
  methods: {
    productDes(id) {
      this.$router.push("/products/" + id);
    },
    goToCatalog() {
      this.$router.push("/catalog")
    },
    async getBanner() {
      const BannerResp = await this.$axios("https://back.rizaherb.kz/index.php?route=feed/rest_api/banners&id=11");
      const { image } = BannerResp.data.data[0];
      this.banner = image;
    }
  },
  created() {
    this.getBanner()
  }
};
</script>

<style scoped>
.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10px;
}

.products__banner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}

.products__banner img {
  width: 100%;
  max-width: 1440px;
  object-fit: contain;
}

.products__img {
  max-width: 370px;
  position: relative;
}

img {
  width: 100%;
  height: auto;
}

.evanorm {
  padding-top: 74px;
}

.evanorm__part,
.marsilan__part,
.sulu__part {
  display: flex;
  justify-content: space-evenly;
  column-gap: 5rem;
  /* width: 100%;
  max-width: 1440px; */
}

.evanorm__part .evanorm__text,
.marsilan__part .marsilan__text,
.sulu__part .sulu__text {
  max-width: 520px;
  flex: 1 1 50%;
}

.products__img {
  flex: 1 1 50%;
}

.evanorm__part .evanorm__text h2,
.marsilan__part .marsilan__text h2,
.sulu__part .sulu__text h2 {
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  font-style: normal;
  text-align: left;
  padding-bottom: 26px;
  text-transform: uppercase;
}

.evanorm__part .evanorm__text p,
.marsilan__part .marsilan__text p,
.sulu__part .sulu__text p {
  text-align: left;
  font-size: 14px;
  line-height: 17px;
}

.evanorm__part .evanorm__about,
.marsilan__part .marsilan__about,
.sulu__part .sulu__about {
  text-align: center;
}

.evanorm__about button,
.marsilan__about button,
.sulu__about button {
  padding: 6px 84px;
  border-radius: 5px;
  color: #ffffff;
  background-color: #75b051;
}

.evanorm__part:after,
.marsilan__part:after,
.sulu__part:after {
  content: "";
  display: block;
  width: 100%;
  max-width: 611px;
  height: 1px;
  background-color: #75b051;
  margin: 50px auto;
}

/* .sulu__part {
  z-index: 2;
}

.sulu__part .sulu__img2 {
  position: absolute;
  top: 32%;
  left: 40%;
  z-index: 10;
} */

/* @media (min-width: 1440px) {
.evanorm__part,
.marsilan__part,
.sulu__part {
  
}
} */
@media (min-width: 1440px) {
  .evanorm__part,
  .marsilan__part,
  .sulu__part {
    padding: 0 10rem;
  }
}
</style>
